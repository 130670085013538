import { createSlice } from "@reduxjs/toolkit";

type InitStateType = {
  type: "products" | "packs" | "facings";
  source_id: string;
  dataset_id: string;
  audit_dataset: string;
};

export const initialState: InitStateType = {
  type: "products",
  source_id: "",
  dataset_id: "",
  audit_dataset: "",
};

export const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setType: (state, action) => {
      state.type = action.payload;
    },
    setSourceId: (state, action) => {
      state.source_id = action.payload;
    },
    setDatasetId: (state, action) => {
      state.dataset_id = action.payload;
    },
    setAuditDataset: (state, action) => {
      state.audit_dataset = action.payload;
    },
  },
});

export const { setType, setSourceId, setDatasetId, setAuditDataset } =
  filterSlice.actions;

export default filterSlice.reducer;
