import { createTheme } from "@mui/material/styles";

export const palette = {
  primary: {
    main: "#D8E3EC",
    dark: "#036AB5",
    light: "#fff",
  },
  secondary: {
    main: "#036AB5",
  },
};

const theme = createTheme({
  palette,
  components: {
    MuiPaper: {
      defaultProps: {
        sx: { p: "10px" },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: palette.primary.main,
          },
          "&.Mui-selected": {
            backgroundColor: palette.primary.dark,
          },
          "&.Mui-selected:hover": {
            backgroundColor: palette.primary.main,
          },
        },
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        componentsProps: { paper: { sx: { p: 0 } } },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          "&.Mui-checked": {
            color: palette.primary.dark,
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: "contained",
        disableElevation: true,
        color: "secondary",
      },
      styleOverrides: {
        root: {
          "&:hover": {
            color: palette.primary.light,
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          "&.Mui-checked": {
            color: palette.primary.dark,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            color: palette.primary.light,
          },
        },
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        color: "secondary",
      },
    },
    MuiInputLabel: {
      defaultProps: {
        color: "secondary",
      },
    },
  },
  typography: {
    allVariants: {
      color: "#263B47",
    },
    fontFamily: ["Suisse Intl", "sans-sertif"].join(","),
  },
});

export default theme;
