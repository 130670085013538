import { configureStore } from "@reduxjs/toolkit";
import interfaceReducer from "./reducers/interfaceReducer";
import filterReducer from "./reducers/filterReducer";
import contentReducer from "./reducers/contentReducer";
import facingReducer from "./reducers/facingReducer";
import adminFilterReducer from "./reducers/adminFilterReducer";
import requestsReducer from "./reducers/requestsReducer";
import selectedItemReducer from "./reducers/selectedItems";
import newItemsReducer from "./reducers/newItemsReducer";

export const store = configureStore({
  reducer: {
    interface: interfaceReducer,
    filter: filterReducer,
    content: contentReducer,
    facing: facingReducer,
    adminFilter: adminFilterReducer,
    requests: requestsReducer,
    selectedItems: selectedItemReducer,
    newItems: newItemsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
