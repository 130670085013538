import { createSlice } from "@reduxjs/toolkit";

type InitStateType = {
  item1: {
    id: number;
    type: "product" | "pack" | "visual";
  } | null;
  item2: {
    id: number;
    type: "product" | "pack" | "visual";
  } | null;
};

export const initialState: InitStateType = {
  item1: null,
  item2: null,
};

export const selectedItemSlice = createSlice({
  name: "selectedItems",
  initialState,
  reducers: {
    setSelectedItem1: (state, action) => {
      state.item1 = action.payload;
    },
    setSelectedItem2: (state, action) => {
      state.item2 = action.payload;
    },
    setSelectedItem: (state, action) => {
      if (!state.item1) {
        state.item1 = action.payload;
        return;
      }
      if (!state.item2) {
        state.item2 = action.payload;
        return;
      }
    },
    setClearSelectedItems: (state) => {
      state.item1 = null;
      state.item2 = null;
    },
  },
});

export const {
  setSelectedItem1,
  setSelectedItem2,
  setSelectedItem,
  setClearSelectedItems,
} = selectedItemSlice.actions;

export default selectedItemSlice.reducer;
