import { createSlice } from "@reduxjs/toolkit";
import { FacingType, PackType, ProductType } from "../../Types";

type InitStateType = {
  pack: PackType | null;
  product: ProductType | null;
  selectedFacing: FacingType | null;
};

const initialState: InitStateType = {
  pack: null,
  product: null,
  selectedFacing: null,
};

export const facingSlice = createSlice({
  name: "facing",
  initialState,
  reducers: {
    setPack: (state, action) => {
      state.pack = action.payload;
    },
    setProduct: (state, action) => {
      state.product = action.payload;
    },
    setSelectedFacing: (state, action) => {
      state.selectedFacing = action.payload;
    },
  },
});

export const { setPack, setProduct, setSelectedFacing } = facingSlice.actions;

export default facingSlice.reducer;
