import { createSlice } from "@reduxjs/toolkit";

type Keys =
  | "new_products"
  | "new_packs"
  | "new_facings"
  | "new_datasets"
  | "new_sources"
  | "new_users";

type InitStateType = {
  [key in Keys]: {
    [key: number]: boolean;
  };
};

const initialState: InitStateType = {
  new_products: {},
  new_packs: {},
  new_facings: {},
  new_datasets: {},
  new_sources: {},
  new_users: {},
};

type SetNewItemsIdType = (
  state: InitStateType,
  action: { payload: {}; type: Keys }
) => void;

type ReducersType = {
  setNewItemsId: SetNewItemsIdType;
};

export const newItemsSlice = createSlice({
  name: "newItems",
  initialState,
  reducers: {
    setNewItemsId: (state, action) => {
      const { items, type } = action.payload;
      state[("new_" + type) as Keys] = items;
    },
    addToNewItemsId: (state, action) => {
      const { id, type } = action.payload;
      const newIdList = Object.assign({}, state[("new_" + type) as Keys]);
      newIdList[id] = true;
      state[("new_" + type) as Keys] = newIdList;
    },
    removeFromNewItemsId: (state, action) => {
      const { id, type } = action.payload;
      const newIdList = Object.assign({}, state[("new_" + type) as Keys]);
      delete newIdList[id];
      state[("new_" + type) as Keys] = newIdList;
    },
  },
});

export const { setNewItemsId, addToNewItemsId, removeFromNewItemsId } =
  newItemsSlice.actions;

export default newItemsSlice.reducer;
