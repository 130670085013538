import { createSlice } from "@reduxjs/toolkit";
import { Request } from "../../Types/requests";

type InitStateType = {
  requests: Request[];
  error: string;
  queryError: { [key: string]: string[] };
};

const initialState: InitStateType = {
  requests: [],
  error: "",
  queryError: {},
};

export const requestSlice = createSlice({
  name: "requests",
  initialState,
  reducers: {
    setRequests: (state, action) => {
      state.requests = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setQueryError: (state, action) => {
      state.queryError = action.payload;
    },
  },
});

export const { setRequests, setError, setQueryError } = requestSlice.actions;

export default requestSlice.reducer;
