import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { store } from "./store";
import { Provider } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter } from "react-router-dom";
import theme from "./theme";
import GlobalScrollStyles from "./theme/globalScrollBarStyles";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { FallbackWrapper } from "./components/CommonComponents/StyledComponents";
import { CircularProgress } from "@mui/material";
const App = React.lazy(() => import("./App"));

declare global {
  interface Window {
    API_URL: string;
    SENTRY_DSN: string;
    SENTRY_TRACES_RATE: string;
    SENTRY_ENVIRONMENT: string;
  }
}

const { SENTRY_DSN, SENTRY_TRACES_RATE, SENTRY_ENVIRONMENT } = window;

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: +SENTRY_TRACES_RATE,
  environment: SENTRY_ENVIRONMENT,
});

const isMac = navigator.platform.includes("Mac");

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          {!isMac && <GlobalScrollStyles />}
          <Suspense
            fallback={
              <FallbackWrapper>
                <CircularProgress color="secondary" />
              </FallbackWrapper>
            }
          >
            <App />
          </Suspense>
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
