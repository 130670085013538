import { createSlice } from "@reduxjs/toolkit";
import { FacingType, PackType, ProductType } from "../../Types";

type InitStateType = {
  productsObject: {
    products: ProductType[];
    pagination: { total_records: number };
  };
  packsObject: {
    packs: PackType[];
    pagination: { total_records: number };
  };
  facingsObject: {
    facing_images: FacingType[];
    pagination: { total_records: number };
  };
  selectedProduct: ProductType | null;
};

const initialState: InitStateType = {
  productsObject: { products: [], pagination: { total_records: 0 } },
  packsObject: { packs: [], pagination: { total_records: 0 } },
  facingsObject: { facing_images: [], pagination: { total_records: 0 } },
  selectedProduct: null,
};

export const contentSlice = createSlice({
  name: "content",
  initialState,
  reducers: {
    setProductsObject: (state, action) => {
      state.productsObject = action.payload;
    },
    setPacksObject: (state, action) => {
      state.packsObject = action.payload;
    },
    setFacingsObject: (state, action) => {
      state.facingsObject = action.payload;
    },
    setSelectedProduct: (state, action) => {
      state.selectedProduct = action.payload;
    },
  },
});

export const {
  setProductsObject,
  setPacksObject,
  setFacingsObject,
  setSelectedProduct,
} = contentSlice.actions;

export default contentSlice.reducer;
