import { createSlice } from "@reduxjs/toolkit";
import { DatasetType, SourceType, UserType } from "../../Types";

type InitStateType = {
  type: "users" | "datasets" | "sources";
  usersObject: {
    users: UserType[];
    pagination: { total_records: number };
  };
  usersPage: number;
  datasetsObject: {
    datasets: DatasetType[];
    pagination: { total_records: number };
  };
  datasetsPage: number;
  sourcesObject: {
    sources: SourceType[];
    pagination: { total_records: number };
  };
  sourcesPage: number;
};

export const initialState: InitStateType = {
  type: "users",
  usersObject: { users: [], pagination: { total_records: 0 } },
  usersPage: 0,
  datasetsObject: { datasets: [], pagination: { total_records: 0 } },
  datasetsPage: 0,
  sourcesObject: { sources: [], pagination: { total_records: 0 } },
  sourcesPage: 0,
};

export const adminFilterSlice = createSlice({
  name: "adminFilter",
  initialState,
  reducers: {
    setType: (state, action) => {
      state.type = action.payload;
    },
    setUsersObject: (state, action) => {
      state.usersObject = action.payload;
    },
    setUsersPage: (state, action) => {
      state.usersPage = action.payload;
    },
    setDatasetsObject: (state, action) => {
      state.datasetsObject = action.payload;
    },
    setDatasetsPage: (state, action) => {
      state.datasetsPage = action.payload;
    },
    setSourcesObject: (state, action) => {
      state.sourcesObject = action.payload;
    },
    setSourcesPage: (state, action) => {
      state.sourcesPage = action.payload;
    },
  },
});

export const {
  setType,
  setUsersObject,
  setUsersPage,
  setDatasetsObject,
  setDatasetsPage,
  setSourcesObject,
  setSourcesPage,
} = adminFilterSlice.actions;

export default adminFilterSlice.reducer;
