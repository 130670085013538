import { styled } from "@mui/material/styles";

export const StatusLine = styled("div")({
  background: "#0388D1",
  marginTop: -24,
  marginBottom: 10,
  color: "#fff",
  padding: "5px 0",
  textAlign: "center",
  width: "100%",
  borderRadius: "5px 5px 0 0",
});

export const FormErrorText = styled("div")({
  fontSize: 12,
  marginTop: 2,
  color: "red",
});

export const FallbackWrapper = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100vh",
});

export const VerticalFlex = {
  display: "flex",
  flexDirection: "column" as const,
  gap: "10px",
  background: "#fff",
};
