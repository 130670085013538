import { createSlice } from "@reduxjs/toolkit";

type InitStateType = {
  showSidebar: boolean;
  loading: boolean;
};

const initialState: InitStateType = {
  showSidebar: true,
  loading: false,
};

export const interfaceSlice = createSlice({
  name: "interface",
  initialState,
  reducers: {
    setShowSidebar: (state, action) => {
      state.showSidebar = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { setShowSidebar, setLoading } = interfaceSlice.actions;

export default interfaceSlice.reducer;
